//===========================================//
//             Table of Contents
//===========================================//
//--------------- Imports --//
//--------------- Variables --//
//--------------- Start the front screen video (camera) --//
//--------------- Load Fonts --//
//--------------- Load Images --//
//--------------- Start function: sets setup 1 --//
//--------------- Take Photo: takes photo and sets frame --//
//--------------- Photo countdown: 3,2,1 --//
//--------------- Post Photo to social stream --//
//--------------- Show final image & Reset photo booth back to start --//
//--------------- Use Effect --//
//--------------- Returned HTML --//
//===========================================//

//--------------- Imports --//
import React, { useState, useRef, useEffect } from "react";
import { base64StringToBlob } from "blob-util";
import startLogos from "../assets/original_size/krustyKrabStartLogos.png";
import touchIcon from "../assets/original_size/krustyKrabStartButton.png";
import startPhotos from "../assets/original_size/krustyKrabTakePhotoButton.png";
import countdown1 from "../assets/original_size/krustyKrabCountdown1.png";
import countdown2 from "../assets/original_size/krustyKrabCountdown2.png";
import countdown3 from "../assets/original_size/krustyKrabCountdown3.png";
import overlay from "../assets/original_size/krustyKrabFrame1.png";
import newOverlay from "../assets/original_size/krustyKrabFrame3-nycc.png";
import qrText from "../assets/original_size/krustyKrabQRText.png";
import restartPhotos from "../assets/original_size/krustyKrabRestartButton.png";
import { QRCodeSVG } from "qrcode.react";

function Home() {
	//--------------- Variables --//
	const videoRef = useRef(null);
	const photoRef = useRef(null);
	let [photo, setCountDownNumber] = useState(countdown3);
	const [hasPhoto, setHasPhoto] = useState(false);
	const [step, setStep] = useState(0);
	const [data, setData] = useState(false);
	const [fontsLoaded, setFontsLoaded] = useState(false);
	const [imagesLoaded, setImagesLoaded] = useState(false);
	const [qrCode, setQrCode] = useState(null);

	//--------------- Start the front screen video (camera) --//
	//For ipad: width: 793.33, height: 595
	//For computer: width: 595, height: 793.33
	const getVideo = () => {
		let facingMode = "user"; // Default to front camera

		navigator.mediaDevices
			.getUserMedia({
				video: {
					facingMode: facingMode,
				},
			})
			.then((stream) => {
				const video = videoRef.current;
				video.srcObject = stream;
				video.setAttribute("playsinline", true); // Add playsinline attribute
				video.setAttribute("muted", true); // Add muted attribute
				video.setAttribute("autoplay", true); // Add autoplay attribute

				const findVideo = setInterval(() => {
					if (video.srcObject) {
						video.srcObject = stream;
						var playPromise = video.play();
						if (playPromise !== undefined) {
							playPromise
								.then((_) => {
									console.log("Video playback started successfully");
								})
								.catch((error) => {
									console.error("Error starting video playback:", error);
								});
						}

						clearInterval(findVideo);
					}
				}, 1000); // Check every 1 second for video readiness

				// Add click event listener to toggle camera
				video.addEventListener("click", function () {
					facingMode = facingMode === "user" ? "environment" : "user";

					const constraints = {
						video: {
							facingMode: facingMode,
						},
					};

					navigator.mediaDevices
						.getUserMedia(constraints)
						.then(function (stream) {
							video.srcObject = stream;
						})
						.catch(function (error) {
							console.error("Error switching camera:", error);
						});
				});
			})
			.catch((err) => {
				console.error("Error accessing the camera:", err);
			});
	};

	//--------------- Load Fonts --// // For loading
	function loadFonts() {
		document.fonts.ready.then(() => {
			setFontsLoaded(true);
			const loadedFonts = Array.from(document.fonts.values());
			console.log("Loaded Fonts:", loadedFonts);
		});
	}

	//--------------- Load Images --// // For loading
	function loadImages() {
		const images = [startLogos, touchIcon, startPhotos, countdown1, countdown2, countdown3, overlay, newOverlay, qrText, restartPhotos];

		const imagePromises = images.map((path) => {
			return new Promise((resolve) => {
				const img = new Image();
				img.src = path;
				img.onload = () => resolve();
			});
		});

		Promise.all(imagePromises).then(() => {
			setImagesLoaded(true);
			console.log("all images loaded");
		});
	}

	//--------------- Start function: sets setup 1 --//
	const start = () => {
		setStep(1);
	};

	//--------------- Take Photo: takes photo and sets frame --//
	const takePhoto = () => {
		// IPAD
		// const height = 1400;
		// const width = 1024;
		const height = 1920;
		const width = 1080

		let video = videoRef.current;
		let photo = photoRef.current;

		photo.width = width;
		photo.height = height;

		let ctx = photo.getContext("2d");

		// WITH OVERLAY
		let drawing = new Image();

		// DRAW THE SCREENSHOT POSITIONED BELOW THE FRAME
		// newOverlay img is 1080 x 1920
		//ctx.drawImage(video, 100, 50, 820, 1093.23); //image, x-coordinate, y-coordinate, image width, image height
		ctx.drawImage(video, 140, 400, 820, 1093.23); 
		drawing.src = newOverlay; // can also be a remote URL e.g. http://
		drawing.crossOrigin = "anonymous";

		// AFTER THE SCREENSHOT IS LOADED DRAW THE FRAME ON TOP OF THE SCREENSHOT
		drawing.onload = function () {
			ctx.drawImage(drawing, 0, 0, width, height);
			postPicture(photo.toDataURL().slice(22));
		};

		setHasPhoto(true);
		setStep(3);
	};

	//--------------- Photo countdown: 3,2,1 --//
	function photoCountdown() {
		// For Images (Not Gifs)
		setStep(2);
		console.log("Start Photo Count Down/3");
		let countDownTime = 2; // Is 3 seconds

		// 1 second count down
		let countDownTimer = setInterval(function () {
			console.log(countDownTime);

			if (countDownTime <= 0) {
				clearInterval(countDownTimer);
				takePhoto();
			}

			if (countDownTime == 3) {
				setCountDownNumber(countdown3);
			} else if (countDownTime == 2) {
				setCountDownNumber(countdown2);
			} else if (countDownTime == 1) {
				setCountDownNumber(countdown1);
			}

			countDownTime -= 1;
		}, 1000);
	}

	//--------------- Post Photo to social stream --//
	const postPicture = (b64) => {
		// Variables
		const baseURL = process.env.REACT_APP_PUBLIC_API_BASE_URL;
		const apiKey = process.env.REACT_APP_PUBLIC_READ_ONLY_TOKEN;
		const eventId = process.env.REACT_APP_PUBLIC_EVENT_ID;
		const photoStreamId = process.env.REACT_APP_PUBLIC_PHOTOSTREAM_ID;

		const photoStreamUploadURL = `${baseURL}/api/v1/public/events/${eventId}/photostreams/${photoStreamId}/photos`;
		const retrievalURL = `https://krustykrabphotomoment.eventfinity.co/`;

		// Convert the image data to a Blob object
		const contentType = "image/png";
		const blob = base64StringToBlob(b64, contentType);

		// Create a new FormData instance
		const formData = new FormData();

		// Append the necessary data to the FormData
		formData.append("event_attendee_id", "374829327");
		formData.append("photo_caption", "image sent from app");

		// Make file name
		const date = new Date();
		const fileName = `krusty_krab_photo_${date}.jpg`;
		fileName.replace(/\s/g, "");

		// Append the image file to the FormData
		formData.append("photo", blob, fileName); // TODO - SET THIS TO LOCAL SAVED PHOTO

		// Specify the request options
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${apiKey}`,
			},
			body: formData,
		};

		//Perform the POST request
		fetch(photoStreamUploadURL, requestOptions)
			.then((response) => {
				if (!response.ok) {
					console.log("Social Stream Upload Error: ", response);
					throw new Error(`Error! Status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				console.log("Social Stream Upload Status: ", data);
				setQrCode(`${retrievalURL}?id=${data.data.id}`);
				setStep(4);
			})
			.catch((error) => {
				console.error("Social Stream Upload Error: ", error);
				setStep(4);
			});
	};

	//--------------- Show final image & Reset photo booth back to start --//
	const restart = () => {
		// SHOW FINAL IMAGE AND RESTART
		const canvas = document.querySelector("canvas");
		const context = canvas.getContext("2d");
		context.clearRect(0, 0, canvas.width, canvas.height);
		setStep(0);
		setHasPhoto(false);
		setCountDownNumber(countdown3);
	};

	//--------------- Use Effect --//
	useEffect(() => {
		loadFonts(); // For loading
		loadImages(); // For loading
		getVideo();
	}, []);

	//--------------- Returned HTML --// // For loading ({fontsLoaded && imagesLoaded ?)
	return (
		<div className="App">
			{fontsLoaded && imagesLoaded ? (
				<>
					<div className={"startScreen" + (step === 0 ? "" : " hidden")} onClick={start}>
						<img src={startLogos} className="startLogos" />
						<img src={touchIcon} className="touch" />
						<p className="footer">
							© 2024 VIACOM INTERNATIONAL INC. ALL RIGHTS RESERVED. |{" "}
							<a target="_blank" href="https://privacy.paramount.com/en/childrens?r=www.nick.com">
								Privacy Policy
							</a>
						</p>
					</div>
					<div className={"camera" + (step === 1 || step === 2 ? "" : " hidden")}>
						<div className="pictime"></div>
						<div className="videoWrapper">
							<video autoplay muted webkit-playsinline ref={videoRef}></video>
						</div>
						<img src={startPhotos} onClick={photoCountdown} className="btnPhoto" />
						<p className="footer">
							© 2024 VIACOM INTERNATIONAL INC. ALL RIGHTS RESERVED. |{" "}
							<a target="_blank" href="https://privacy.paramount.com/en/childrens?r=www.nick.com">
								Privacy Policy
							</a>
						</p>
					</div>
					<div className={"countdown" + (step === 2 ? "" : " hidden")}>
						<img className="count" src={photo} />
					</div>
					<div className={"loading" + (step === 3 ? "" : " hidden")}>
						<div className="loadingContainer">
							<p className="loadingText">Loading...</p>
						</div>
					</div>
					<div className={"result" + (step === 4 ? "" : " hidden")}>
						<canvas className="hidden" ref={photoRef}></canvas>
						<div className="finalText">
							<img src={qrText} className="qrText" />
							<QRCodeSVG value={qrCode} size={175} />
							<img src={restartPhotos} onClick={restart} className="btnRestart" />
						</div>
						<p className="footer">
							© 2024 VIACOM INTERNATIONAL INC. ALL RIGHTS RESERVED. |{" "}
							<a target="_blank" href="https://privacy.paramount.com/en/childrens?r=www.nick.com">
								Privacy Policy
							</a>
						</p>
					</div>
				</>
			) : (
				<div className="loadingContainer">
					<p className="loadingText">Loading...</p>
				</div>
			)}
		</div>
	);
}

export default Home;
